@font-face {
ascent-override: 90%;
font-family: '__arminFont_3a3be3';
src: url(/_next/static/media/f8c767ee538731e3-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
ascent-override: 90%;
font-family: '__arminFont_3a3be3';
src: url(/_next/static/media/058150047b5a6f4e-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
ascent-override: 90%;
font-family: '__arminFont_3a3be3';
src: url(/_next/static/media/f48748d064c1146e-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 800;
font-style: normal;
}@font-face {font-family: '__arminFont_Fallback_3a3be3';src: local("Arial");ascent-override: 76.58%;descent-override: 21.47%;line-gap-override: 39.02%;size-adjust: 101.99%
}.__className_3a3be3 {font-family: '__arminFont_3a3be3', '__arminFont_Fallback_3a3be3'
}.__variable_3a3be3 {--font-armin: '__arminFont_3a3be3', '__arminFont_Fallback_3a3be3'
}

@font-face {
font-family: '__carmaTitleFont_dd9b62';
src: url(/_next/static/media/52fc5978e7ce83ce-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 800;
font-style: normal;
}@font-face {font-family: '__carmaTitleFont_Fallback_dd9b62';src: local("Arial");ascent-override: 101.83%;descent-override: 32.39%;line-gap-override: 0.00%;size-adjust: 96.48%
}.__className_dd9b62 {font-family: '__carmaTitleFont_dd9b62', '__carmaTitleFont_Fallback_dd9b62';font-weight: 800;font-style: normal
}.__variable_dd9b62 {--font-carmatitle: '__carmaTitleFont_dd9b62', '__carmaTitleFont_Fallback_dd9b62'
}

